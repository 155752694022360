export default {
  setList(state, response) {
    state.list = response;
  },
  clearList(state) {
    state.list = [];
  },
  addBuilderPlayer(state, player) {
    if (state.builder.players.findIndex(({ id }) => (id === player.id)) === -1) {
      state.builder.players = [...state.builder.players, player]; 
    }
  },
  removeBuilderPlayer(state, player) {
    const index = state.builder.players.findIndex(({ id }) => (id === player.id));
    if (index > -1) {
      state.builder.players.splice(index, 1);
    }
  },
  clearBuilderPlayers(state) {
    state.builder.players = [];
  },
  mergeBuilderTeamPlayers(state) {
    if (state.builder.players.length > 0) {
      state.builder.players
        .filter(({ id }) => (state.builder.team.players.findIndex(player => player.id === id) === -1))
        .forEach(player => state.builder.team.players.push(player));
    }
  },
  updateBundleTeamPlayers(state, index) {
    if (state.bundle.teams[index]) {
      state.bundle.teams[index].players = [];
      state.builder.players
        .filter(({ id }) => (state.bundle.teams[index].players.findIndex(player => player.id === id) === -1))
        .forEach(player => state.bundle.teams[index].players.push(player));
    }
  },
  removeBundleTeamPlayer(state, { index, id }) {
    if (state.bundle.teams[index] && Array.isArray(state.bundle.teams[index].players)) {
      const playerIndex = state.bundle.teams[index].players.findIndex(player => (player.id === id));
      if (index > -1) {
        state.bundle.teams[index].players.splice(playerIndex, 1);
      }
    }
  },
  removeTeamPlayer(state, { id }) {
    const index = state.builder.team.players.findIndex(player => (player.id === id));
    if (index > -1) {
      state.builder.team.players.splice(index, 1);
      const builderIndex = state.builder.players.findIndex(player => (player.id === id));
      if (builderIndex > -1) {
        state.builder.players.splice(builderIndex, 1);
      }
    }
  },
  clearBuilderTeam(state) {
    state.builder.team = {
      name: '', description: '', players: []
    };
    state.builder.players = [];
  },
  setTeamInfo(state, { name, description }) {
    state.builder.team.name = name;
    state.builder.team.description = description;
  },
  setTeamBundleInfo(state, { index, name, description }) {
    if (state.bundle.teams[index]) {
      state.bundle.teams[index].name = name;
      state.bundle.teams[index].description = description;
    }
  },
  updateTeamBundle(state, { index, name, description, players }) {
    if (state.bundle.teams[index]) {
      if (name && typeof name === 'string') {
        state.bundle.teams[index].name = String(name);
      }
      if (description && typeof description === 'string') {
        state.bundle.teams[index].description = String(description); 
      }
      if (players && Array.isArray(players) && players.length > 0) {
        state.bundle.teams[index].players = players; 
      }
    }
  },
  deleteTeamBundle(state, index) {
    if (state.bundle.teams[index]) {
      state.bundle.teams.splice(index, 1);
    }
  },
  addTeamToBundle(state, payload = null) {
    state.bundle.teams.push(payload || state.builder.team);
  },
  removeTeamBundle(state, { index }) {
    state.bundle.teams.splice(index, 1);
  },
  setBundleTournamentId(state, id) {
    state.bundle.tournament_id = id;
  },
  setBundleSeasonId(state, id) {
    state.bundle.season_id = id;
  },
  setBundleStageId(state, id) {
    state.bundle.stage_id = id;
  },
  addBundleTeamId(state, id) {
    state.bundle.team_ids.push(id);
  },
  clearBundle(state) {
    state.bundle = {
      teams: [],
      tournament_id: null,
      season_id: null,
      team_ids: [],
    };
  },
  clearTournament(state) {
    state.tournament = {
      id: null, seasons: [], created_at: null, updated_at: null, status: null, participant_type: null,
      name: null, description: null, color: null, sport: null, icon: null, team_limit: 0, owner: null,
    };
  },
  setTournament(state, payload) {
    state.tournament = payload;
  },
  clearTeam(state) {
    state.team = {
      id: '', name: '', type: '', players: [], created_at: null, updated_at: null,
    };
  },
  updateTeamInfo(state, { name, description }) {
    state.team = { ...state.team, name, description };
  },
  setTeam(state, payload) {
    state.team = payload;
  },
  removePlayerFromTeam(state, { id }) {
    if (state.team && Array.isArray(state.team.players)) {
      const index = state.team.players.findIndex(teamPlayer => (id === teamPlayer.id));
      if (index > -1) {
        state.team.players.splice(index, 1);
      }
    }
  },
  mergeTeamPlayers(state) {
    if (state.builder.players.length > 0) {
      state.builder.players
        .filter(builderPlayer => (state.team.players.findIndex(teamPlayer => (teamPlayer.player.id === builderPlayer.player.id)) === -1))
        .forEach(({ player }) => state.team.players.push({
          player, id: null, status: 'I'
        }));
    }
  },
  setStats(state, payload) {
    state.stats = payload;
  },
  clearStats(state) {
    state.stats = [];
  },
  clearGeoCode(state) {
    state.geoCode = {
      meta: {
        request: '',
        found: 0,
        results: 0,
      },
      results: []
    };
  },
  setGeoCode(state, { meta, results }) {
    state.geoCode.meta = {
      ...meta,
    };
    state.geoCode.results = results;
  },
  setChosenLocation(state, { coordinates, name }) {
    if (coordinates && (typeof coordinates.latitude === 'number' && typeof coordinates.longitude === 'number')) {
      state.chosenLocation.coordinates.latitude = coordinates.latitude;
      state.chosenLocation.coordinates.longitude = coordinates.longitude;
    }
    if (typeof name === 'string') {
      state.chosenLocation.name = name; 
    }
  },
  clearChosenLocation(state) {
    state.chosenLocation = {
      coordinates: {
        latitude:  0.0,
          longitude: 0.0,
      },
      name: '',
    };
  },
  setFreezeLocation(state, value) {
    state.freezeLocation = value;
  }
};
