export default {
  setSeasons(state, payload) {
    state.seasons = payload;
  },
  setStages(state, payload) {
    state.stages = payload;
  },
  setTeams(state, payload) {
    state.teams = payload;
  },
  setSeries(state, payload) {
    state.series = payload;
  },
  updateMatch(state, payload) {
    state.series.forEach(({ matches }) => {
      matches.forEach((match) => {
        if (payload.id === match.id) {
          match.score1 = payload.score1;
          match.score2 = payload.score2;
          match.start = payload.start;
        }
      });
    });
  },
}