import api from '../../../api';
import { Preferences } from '@capacitor/preferences';

export default {
  async saveToken({ rootGetters }) {
    try {
      const device = rootGetters['general/getDeviceID'];
      const FCMToken = await Preferences.get({ key: 'FCMToken' });
      if (FCMToken && FCMToken.value) {
        await api.post('/notification/android_token/', { token: FCMToken.value, device });
      } else {
        console.error('Undefined FCM Token: ', FCMToken);
      }
    } catch (error) {
      console.error(error);
    }
  },
  async deleteToken({ rootGetters }) {
    try {
      const id = rootGetters['general/getDeviceID'];
      await api.delete(`/notification/android_token/${id}/`);
    } catch (error) {
      console.log(error);
    }
  },
  async getList({ commit, getters, dispatch }, { force } = { force: false }) {
    if (getters.getFetching === true) return;
    if (force) {
      commit('clearList');
    } else if (getters.list.length > 0) return;
    
    commit('setFetching', true);
    try {
      const { data } = await api.get('/notification/');
      const { results } = data;
      commit('setList', results);
    } catch (error) {
      console.error(error);
    } finally {
      commit('setFetching', false);
    }
  },
  
  async readTheNotify({ commit }, { id }) {
    try {
      const { data } = await api.patch(`/notification/${id}/`, {
        read: true,
      });
      if (data && typeof data.read === 'boolean') {
        commit('setReadNotify', { id: parseInt(id), value: Boolean(data.read) });
      }
    } catch (error) {
      console.error(error);
    }
  },
}