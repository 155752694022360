<template>
  <ion-tabs class="profile-tabs">
    <!-- Content -->
    <ion-router-outlet />
    <!-- Tab bar -->
    <ion-tab-bar :class="[
      'profile-tabs__bar',
      { 'profile-tabs__bar_deactivate': deactivateTabsBar }
    ]" :mode="deviceMode" slot="bottom">

      <ion-tab-button :mode="deviceMode" tab="feed" href="/tournaments" layout="icon-top">
        <ion-icon :mode="deviceMode" :icon="trophyOutline"></ion-icon>
        <ion-label :mode="deviceMode" v-html="$t('menu.tournaments')" />
      </ion-tab-button>

      <ion-tab-button :mode="deviceMode" tab="news" href="/notifications" layout="icon-top">
        <ion-badge v-if="unreadCount > 0" color="danger" :mode="deviceMode" v-html="unreadCount" />
        <ion-icon :mode="deviceMode" :icon="peopleOutline" />
        <ion-label :mode="deviceMode" v-html="$t('menu.notifications')" />
      </ion-tab-button>

      <ion-tab-button :mode="deviceMode" tab="profile" href="/profile" layout="icon-top">
        <ion-icon :mode="deviceMode" :icon="personCircleOutline" />
        <ion-label :mode="deviceMode" v-html="$t('menu.profile')" />
      </ion-tab-button>
      
    </ion-tab-bar>
  </ion-tabs>
</template>

<script>
import {
  IonIcon, IonLabel, IonTabBar, IonBadge,
  IonTabButton, IonTabs, IonPage, IonRouterOutlet,
} from '@ionic/vue';
import { mapGetters } from 'vuex';
import { trophyOutline, peopleOutline, personCircleOutline } from 'ionicons/icons';
import { useRoute } from 'vue-router';

export default {
  name: 'BottomMenu',
  components: {
    IonIcon, IonLabel, IonTabBar, IonTabButton, IonTabs, IonRouterOutlet, IonPage,
    IonBadge,
  },
  setup() {
    const route = useRoute();
    return { trophyOutline, peopleOutline, personCircleOutline, route };
  },
  computed: {
    ...mapGetters({
      unreadCount: 'notifications/unreadCount',
      deviceMode: 'general/getDeviceMode',
      clientOffline: 'general/clientOffline',
    }),
    deactivateTabsBar() {
      return Boolean((this.route && typeof this.route.name === 'string') && (
        (String(this.route.name) === 'invite-page') ||
        (String(this.route.name) === 'location')
      ));
    },
  },
}
</script>

<style lang="scss" scoped>
.profile-tabs {
  &__bar {
    transition: all 0.3s 0s ease-in-out;
    
    &_deactivate {
      transform: translateY(100%);
      height: 0;
    }
  }
}
</style>