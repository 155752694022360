import api from '../../../api/index';

export default {
  async get({ commit }) {
    try {
      const response = await api.get('/tournament/');
      if (response.data) {
        const { count, results } = response.data;
        if (count > 0) {
          commit('setList', results);
        } else {
          commit('clearList');
        }
      }
    } catch (error) {
      console.log(error);
    }
  },
  fetchTournament({ commit }, { tournament_id } = { tournament_id }) {
    return new Promise((resolve, reject) => {
      commit('clearTournament');
      api.get(`/tournament/${tournament_id}/`).then(({ data }) => {
        commit('setTournament', data);
        resolve(data);
      }).catch(error => reject(error));
    });
  },
  updateTeamInfo({ commit }, { id, name, description }) {
    return new Promise((resolve, reject) => {
      api.patch(`/team/${id}/`, {
        name, description,
      }).then(({ data }) => {
        commit('updateTeamInfo', { ...data });
        resolve(data);
      }).catch(error => reject(error));
    });  
  },
  fetchTeam({ commit }, { team_id }) {
    return new Promise((resolve, reject) => {
      commit('clearTeam');
      api.get(`/team/${team_id}/`).then(({ data }) => {
        commit('setTeam', data);
        resolve(data);
      }).catch(error => reject(error));
    });
  },
  createTournament({ commit }, { name, description, color, sport }) {
    return new Promise((resolve, reject) => {
      api.post('/tournament/', { name, description, color, sport }).then(({ data }) => {
        if (data && data.id) {
          commit('setBundleTournamentId', data.id);
        }
        resolve(data && typeof data.id !== 'undefined' ? data.id : null);
      }).catch(e => reject(e));
    });
  },
  createTeam({ commit }, { name, players }) {
    return new Promise((resolve, reject) => {
      api.post('/team/', { name, players_id: players.map(({ player }) => String(player.id)) }).then(({ data }) => {
        commit('addBundleTeamId', data && typeof data.id !== 'undefined' ? data.id : null);
        resolve(data && typeof data.id !== 'undefined' ? data.id : null);
      }).catch(e => reject(e));
    });
  },
  async createTeams({ getters, dispatch }) {
    return new Promise(async (resolve, reject) => {
      try {
        if (getters.bundle.teams.length > 0) {
          for (const { name, players } of getters.bundle.teams) {
            await dispatch('createTeam', { name, players });
          }
        }
        resolve();
      } catch (e) {
        reject(e);
      }
    });
  },
  async createSeason({ commit, getters }, { name, start }) {
    return new Promise((resolve, reject) => {
      api.post('/season/', {
        name,
        start,
        tournament: getters.bundle.tournament_id,
        teams: getters.bundle.team_ids,
      }).then(({ data }) => {
        commit('setBundleSeasonId', data && typeof data.id !== 'undefined' ? data.id : null);
        resolve(data && typeof data.id !== 'undefined' ? data.id : null);
      }).catch(e => reject(e));
    });
  },
  createStage({ commit, getters }, { name, config, format, start }) {
    return new Promise((resolve, reject) => {
      api.post('/stage/', {
        name, config, format, start,
        season: getters.bundle.season_id,
        teams: getters.bundle.team_ids,
      }).then(({ data }) => {
        commit('setBundleStageId', data && typeof data.id !== 'undefined' ? data.id : null);
        resolve(data && typeof data.id !== 'undefined' ? data.id : null);
      }).catch(e => reject(e));
    });
  },
  async getStats({ commit }, { id }) {
    commit('clearStats');
    try {
      const response = await api.get(`/stage/${id}/stats/`);
      if (response.data && Array.isArray(response.data) && response.data.length > 0) {
        commit('setStats', response.data);
      }
    } catch (error) {
      console.log(error);
    }
  },
  async addingPlayersToTeam({ commit, getters, dispatch, rootGetters }) {
    try {
      const team = getters.getTeam.id;
      const requests = getters.builderPlayers
        .filter(({ player }) => (getters.getTeam.players.findIndex(teamPlayer => (teamPlayer.player.id === player.id)) === -1))
        .map(({ player }) => api.post(`/team/player/`, {
          team, player: player.id,
        }));
      const users = rootGetters['user/getUsers'];
      const userMe = rootGetters['user/getUserMe'];
      const list = ((userMe && userMe.player && userMe.player.id) && (Array.isArray(users.results) && users.results.length > 0)) ? [userMe, ...users.results] : [];
      getters.getTeam.players
        .filter(teamPlayer => (getters.builderPlayers.findIndex(builderPlayer => builderPlayer.player.id === teamPlayer.player.id) === -1))
        .filter(teamPlayer => (list.findIndex(user => user.player.id === teamPlayer.player.id) > -1))
        .forEach(teamPlayer => requests.push(dispatch('removePlayerFromTeam', { id: teamPlayer.id })));
      await Promise.all(requests);
      commit('mergeTeamPlayers');
    } catch (error) {
      console.log(error.message || error);
    }
  },
  async removePlayerFromTeam({ commit, getters }, { id }) {
    try {
      console.log({ id });
      await api.delete(`/team/player/${id}`);
      commit('removePlayerFromTeam', { id });
    } catch (error) {
      console.log(error.message || error);
    }
  },
  async findPlayerInTournament({ rootGetters, getters }, { tournamentId }) {
    return new Promise(async (resolve, reject) => {
      try {
        let userPlayer = null;
        const userMe = rootGetters['user/getUserMe'];
        const tournament = getters.getTournament;
        if ((tournament && tournament.id === tournamentId) && (userMe && userMe.player && typeof userMe.player.id === 'string')) {
          if (tournament.seasons && Array.isArray(tournament.seasons) && tournament.seasons.length > 0) {
            for (let i = 0, len = tournament.seasons.length; i < len; i++) {
              const season = tournament.seasons[i];
              if (season.teams && Array.isArray(season.teams) && season.teams.length > 0) {
                for (let x = 0, len2 = season.teams.length; x < len2; x++) {
                  const teamSeason = season.teams[x];
                  if (teamSeason && typeof teamSeason.id === 'string') {
                    const { data } = await api.get(`/team/${teamSeason.id}/`);
                    if (data.players && Array.isArray(data.players) && data.players.length > 0) {
                      for (let j = 0, len3 = data.players.length; j < len3; j++) {
                        const { player } = data.players[j];
                        if (player.id === userMe.player.id) {
                          resolve(data.players[j]);
                          break;
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
        resolve(userPlayer);
      } catch (error) {
        reject(error);
      }
    });
  },
  async pullOutFromTeam({}, { id, player }) {
    try {
      await api.patch(`/team/player/${id}/`, {
        id: player.id, status: 'R',
      });
    } catch (error) {
      console.log(error.message || error);
    }
  },
  deleteTournament({ commit, dispatch }, { id }) {
    return new Promise(async (resolve, reject) => {
      try {
        await api.delete(`/tournament/${id}/`);
        commit('clearTournament');
        commit('clearList');
        await dispatch('get');
        resolve();
      } catch (error) {
        reject(error);
      }
    });
  },
  inviteTournament({}, { playerId, status }) {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await api.patch(`/team/player/${playerId}/`, { status });
        resolve(response);
      } catch (error) {
        reject(error);
      }
    });
  },
  geoCoding({ commit }, { geocode }) {
    return new Promise(async (resolve, reject) => {
      try {
        // clear geo coding
        commit('clearGeoCode');
        commit('setFreezeLocation', true);
        
        const lang = typeof window !== 'undefined' && window.userLang ? String(window.userLang === 'en' ? 'en_US' : 'ru_RU') : 'ru_RU';
        const apikey = '68ad8d88-7dd7-4b32-8201-d27815228f8c';
        const { data } = await api.get('https://geocode-maps.yandex.ru/1.x/', {
          params: {
            apikey, geocode, format: 'json', lang,
          }
        });
        
        if (data && data.response && (data.response.GeoObjectCollection && data.response.GeoObjectCollection.metaDataProperty && data.response.GeoObjectCollection.featureMember)) {
          const { metaDataProperty, featureMember } = data.response.GeoObjectCollection;
          
          if (metaDataProperty.GeocoderResponseMetaData) {
            const { found, results, request } = metaDataProperty.GeocoderResponseMetaData;
            const payloadMeta = {
              request,
              found,
              results,
            };
            const payloadResults = [];
            if (results > 0) {
              const firstMember = featureMember.shift();
              // Fill chosen location
              if ((firstMember && firstMember.GeoObject) && (firstMember.GeoObject.Point && firstMember.GeoObject.Point.pos) && typeof firstMember.GeoObject.name === 'string') {
                const coordinates = firstMember.GeoObject.Point.pos.split(' ').reduce((acc, value, index) =>
                    Object.defineProperty(acc, index === 1 ? 'latitude' : 'longitude', { value: parseFloat(value), enumerable: false, configurable: false, writable: false }),
                  {});
                commit('setChosenLocation', {
                  name: String(firstMember.GeoObject.name), coordinates,
                }); 
              }
              featureMember.forEach(({ GeoObject }) => {
                const { metaDataProperty, name, description, boundedBy, uri, Point } = GeoObject;
                payloadResults.push({
                  name, description, position: Point, bounds: boundedBy, uri, meta: metaDataProperty,
                });
              });
            }
            commit('setGeoCode', {
              meta: payloadMeta,
              results: payloadResults,
            });
          }
        }
        commit('setFreezeLocation', false);
        resolve(data);
      } catch (error) {
        commit('setFreezeLocation', false);
        reject(error);
      }
    });
  },
};