export default {
  seasons(state) {
    return state.seasons;
  },
  stages(state) {
    return state.stages;
  },
  teams(state) {
    return state.teams;
  },
  series(state) {
    return state.series;
  },
};