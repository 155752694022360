import { Preferences } from '@capacitor/preferences';
import { jwtDecode } from 'jwt-decode';


export default class Token {
  constructor({ access, refresh } = { access: null, refresh: null }) {
    this.access = access || null;
    this.refresh = refresh || null;
    this.user_id = null;
    this.player_id = null;
  }
  
  async storageToken(updateToken = false) {
    const access = await Preferences.get({ key: 'accessToken' });
    const refresh = await Preferences.get({ key: 'refreshToken' });

    if ((access.value === null || updateToken === true) && this.access !== null) {
      await Preferences.set({ key: 'accessToken', value: this.access });
    } else {
      this.access = access.value;
    }
    if ((refresh.value === null || updateToken === true) && this.refresh !== null) {
      await Preferences.set({ key: 'refreshToken', value: this.refresh });
    } else {
      this.refresh = refresh.value;
    }
    if (this.access) {
      try {
        const decodeAccess = jwtDecode(this.access);
        if (decodeAccess) {
          if (typeof decodeAccess.user_id !== 'undefined') {
            this.user_id = decodeAccess.user_id; 
          }
          if (typeof decodeAccess.player_id !== 'undefined') {
            this.player_id = decodeAccess.player_id;
          }
        }
      } catch (error) {
        console.log(error);
        try {
          await Preferences.remove({ key: 'accessToken' });
          await Preferences.remove({ key: 'refreshToken' });
        } catch (errorPreferences) {
          console.log(errorPreferences);
        }
      }
    }
    return { 
      user_id: this.user_id,
      player_id: this.player_id,
      access: this.access,
      refresh: this.refresh,
    };
  }

  async clearToken() {
    const access = await Preferences.get({ key: 'accessToken' });
    const refresh = await Preferences.get({ key: 'refreshToken' });

    if (access.value !== null) {
      await Preferences.remove({ key: 'accessToken' });
    }
    if (refresh.value !== null) {
      await Preferences.remove({ key: 'refreshToken' });
    }
  }
}