<script>
import { modalController } from '@ionic/vue';
import { mapMutations, mapGetters, mapActions } from 'vuex';
import { PushNotifications } from '@capacitor/push-notifications';
import { StatusBar, Style } from '@capacitor/status-bar';
import { App } from '@capacitor/app';
// import { NavigationBar } from '@hugotomazi/capacitor-navigation-bar';
import { Preferences } from '@capacitor/preferences';
import { emitter } from '../composition/useEmitter';

import UserLocale from './UserLocale.vue';
import CardNotification from '../shared/ui/items/CardNotification.vue';
import bot from '../api/bot';

export default {
  mixins: [UserLocale],
  data: () => ({
    modalNotification: null,
    currentNotificationBreakPoint: 1.0,
    notificationTimer: null,
  }),
  computed: {
    ...mapGetters({
      platform: 'general/getPlatform',
      notification: 'general/getNotification',
      networkStatus: 'general/getNetworkStatus',
      deviceId: 'general/getDeviceID',
      deviceInfo: 'general/getDeviceInfo',
      themeMode: 'general/getThemeMode',
    }),
  },
  watch: {
    notification: {
      handler() {
        this.handleNotification();
      },
      immediate: true,
      deep: true,
    }
  },
  mounted() {
    setTimeout(() => {
      this.initApp();
    }, 1000);
    emitter.on('set-breakpoint-notification', async (value) => {
      try {
        if (this.modalNotification && (typeof this.modalNotification.setCurrentBreakpoint === 'function' && typeof this.modalNotification.getCurrentBreakpoint === 'function')) {
          this.currentNotificationBreakPoint = await this.modalNotification.getCurrentBreakpoint();
          if (this.currentNotificationBreakPoint !== value) {
            await this.modalNotification.setCurrentBreakpoint(value);
            this.currentNotificationBreakPoint = value;
          }
        }
      } catch (error) {
        console.log(error);
      }
    });
    emitter.on('dismiss-notification', async () => {
      await this.dismissNotification();
    });
    emitter.on('accept-notification', async () => {
      if (this.notification && this.notification.data != null) {
        console.log('accept-notification:');
        console.log(this.notification.data);
        if (typeof this.notification.data.deepLink === 'string') {
          switch (this.notification.data.deepLink) {
            case 'trm://profile': {
              this.redirectTo({ name: 'profile' });
              break;
            }
            case 'trm://tornaments': {
              this.redirectTo({ name: 'tournaments' });
              break;
            }
            case 'trm://invite-page': {
              this.router.push({
                name: 'invite-page', params: { tournament_id: 77, team_id: '878861dc-229c-4429-9e79-1e075a9ad13b' },
              });
              break;
            }
            case 'trm://notifications': {
              this.redirectTo({ name: 'notifications' });
              break;
            }
            default: break;
          }
        }
      }
      await this.dismissNotification();
    });
    emitter.on('click-card-notification', () => {
      emitter.emit('accept-notification');
      // emitter.emit('set-breakpoint-notification', this.currentNotificationBreakPoint < 1.0 ? 1.0 : 0.5);
    });
  },
  methods: {
    ...mapActions({
      getDeviceID: 'general/getDeviceID',
      getDeviceInfo: 'general/getDeviceInfo',
      getLanguageCode: 'general/getLanguageCode',
    }),
    ...mapMutations({
      setNotification: 'general/setNotification',
      clearNotification: 'general/clearNotification',
    }),
    async initApp() {
      try {
        await this.getDeviceID();
        await this.getDeviceInfo();
        await this.getLanguageCode();
        await this.setupUserLocale();
        if (this.networkStatus === 'online' && this.platform === 'android') {
          await this.initNotifications();
        }
      } catch (error) {
        console.log(error);
      }
    },
    async showNotification({ title, text, acceptText, dismissText }) {
      this.clearNotificationTimer();
      
      try {
        this.currentNotificationBreakPoint = 1.0;
        this.modalNotification = await modalController.create({
            component: CardNotification,
            componentProps: {
              title,
              text,
              acceptText,
              dismissText,
            },
            cssClass: 'dialog__modal',
            initialBreakpoint: 1.0,
            breakpoints: [0.5, 1.0],
            backdropDismiss: true,
            backdropBreakpoint: 0.5,
            canDismiss: data => new Promise((resolve) => {
              if (data === 'dismiss') {
                resolve(true);
              } else {
                // emitter.emit('set-breakpoint-notification', 0.5);
                resolve(true); 
              }
            }),
        });
        await this.modalNotification.present();

        this.notificationTimer = setTimeout(() => {
          this.dismissNotification();
        }, 4000);
      } catch (error) {
        console.log(error);
      }
    },
    clearNotificationTimer() {
      if (this.notificationTimer) {
        clearTimeout(this.notificationTimer);
        this.notificationTimer = null;
      }
    },
    async dismissNotification() {
      try {
        if (this.modalNotification && typeof this.modalNotification.dismiss === 'function') {
          await this.modalNotification.dismiss('dismiss');
        }
        this.modalNotification = null;
        // Dispose delivered notifications
        if (this.notification && this.notification.data != null) {
          await this.disposalDeliveredNotifications();
        }
        this.clearNotification();
      } catch (error) {
        console.log(error);
      }
    },
    async handleNotification() {
      if (this.notification && typeof this.notification.open === 'boolean') {
        if (this.notification.open) {
          if ((typeof this.notification.title == 'string' && typeof this.notification.text == 'string') && (this.notification.title.length > 0 && this.notification.text.length > 0)) {
            const params = {
              title: String(this.notification.title),
              text: String(this.notification.text),
            };
            if (this.notification.data != null) {
              if (typeof this.notification.data.acceptText === 'string' && this.notification.data.acceptText.length > 0) {
                params.acceptText = String(this.notification.data.acceptText);
              }
              if (typeof this.notification.data.dismissText === 'string' && this.notification.data.dismissText.length > 0) {
                params.dismissText = String(this.notification.data.dismissText);
              }
            }
            await this.showNotification(params);
          }
        } else if (this.notification.data != null) {
          await emitter.emit('accept-notification');
        }
      }
    },
    async disposalDeliveredNotifications() {
      try {
        await PushNotifications.removeAllDeliveredNotifications();
      } catch (error) {
        console.log(error);
      }
    },
    redirectTo(to) {
      if (this.router && typeof this.router.push === 'function') {
        this.router.push(to);
      }
    },
    async initNotifications() {
      try {
        await this.registerNotifications();
        await this.getDeliveredNotifications();
      } catch (error) {
        console.log(error);
      }
    },
    async registerNotifications() {
      try {
        if (this.themeMode === 'dark') {
          await StatusBar.setStyle({ style: Style.Dark });
          await StatusBar.setBackgroundColor({ color: '#000000' }); 
        } else {
          await StatusBar.setStyle({ style: Style.Light });
          await StatusBar.setBackgroundColor({ color: '#ffffff' });
        }
        // await NavigationBar.hide();
        
        await this.addListeners();

        let permStatus = await PushNotifications.checkPermissions();
        if (permStatus.receive === 'prompt') {
          await bot(`Request permissions: ${JSON.stringify(permStatus)}`, 'push');
          permStatus = await PushNotifications.requestPermissions();
        }
        if (permStatus.receive !== 'granted') {
          await bot(`Permissions status: ${JSON.stringify(permStatus)}`, 'push');
          console.error('User denied permissions!');
        }
        
        await PushNotifications.register();
        
      } catch (error) {
        console.log(error);
      }
    },
    async addListeners() {
      /**
       * Registration
       */
      await PushNotifications.addListener('registration', async (token) => {
        const FCMToken = String(token.value);
        let savedToken = await Preferences.get({ key: 'FCMToken' });
        if (savedToken && savedToken.value) {
          if (savedToken.value === FCMToken) {
            console.warn('FCM Token in the local storage is valid');
          } else {
            await Preferences.set({ key: 'FCMToken', value: FCMToken });
            await this.FCMTokenLogging(`Updating FCM Token to local storage, value: ${FCMToken}`);
          }
        } else {
          await Preferences.set({ key: 'FCMToken', value: FCMToken });
          await this.FCMTokenLogging(`Save FCM Token to local storage, value: ${FCMToken}`);
        }
      });
      /**
       * Registration error
       */
      await PushNotifications.addListener('registrationError', async (error) => {
        console.error('Registration error: ', error.error);
        await this.FCMTokenLogging(`Registration error: ${JSON.stringify(error.error)}`);
      });
      /**
       * Push notification received
       */
      await PushNotifications.addListener('pushNotificationReceived', async (notification) => {
        console.log('pushNotificationReceived:');
        console.log(notification);
        
        if (notification) {
          this.setNotification({
            title: (typeof notification.title === 'string' && notification.title.length > 0) ? String(notification.title) : '',
            text: (typeof notification.body === 'string' && notification.body.length > 0) ? String(notification.body) : '',
            data: (notification.data != null) ? notification.data : null,
            open: true,
          });
        }
        await this.FCMTokenLogging(`Push notification received: ${JSON.stringify(notification)}`);
      });
      /**
       * Push notification action perfmormed
       */
      await PushNotifications.addListener('pushNotificationActionPerformed', async (notification) => {
        console.log('pushNotificationActionPerformed:');
        console.log(notification);
        await this.FCMTokenLogging(`Push notification action performed: ${JSON.stringify(notification.actionId)}, inputValue: ${JSON.stringify(notification.inputValue)}`);
        const data = notification.notification.data;
        await this.FCMTokenLogging(`Action performed: ${JSON.stringify(notification.notification)}`);
        await this.FCMTokenLogging(`Push data: ${JSON.stringify(data)}`);
        if (notification && typeof notification.actionId === 'string') {
          console.warn('actionId: ', String(notification.actionId));
          switch (String(notification.actionId)) {
            case 'tap': {
              if (notification.notification && notification.notification.data) {
                this.setNotification({
                  data: { ...this.notification.data, ...notification.notification.data },
                  open: false,
                });
                console.log('mutation notification: ', this.notification);
              }
              break;
            }
            default: break;
          }
        }
      });
      /**
       * App state change
       */
      // await App.addListener('appStateChange', ({ isActive }) => {
      //   if (isActive) {
      //     // NavigationBar.hide();
      //   }
      // });
    },
    async getDeliveredNotifications() {
      try {
        const response = await PushNotifications.getDeliveredNotifications();
        if ((response && response.notifications) && (Array.isArray(response.notifications) && response.notifications.length > 0)) {
          console.log('Delivered notifications:');
          response.notifications.forEach((notification) => {
            console.log(notification);
          });
        }
      } catch (error) {
        console.log(error);
      }
    },
    async FCMTokenLogging(text = '', type = 'log') {
      await bot(text, 'push');
      switch (type) {
        case 'warn': console.warn(text); break;
        case 'error': console.error(text); break;
        default: console.log(text); break;
      }
    }
  },
  destroyed() {
    this.clearNotificationTimer();
    this.clearNotification();
    emitter.off('set-breakpoint-notification');
    emitter.off('dismiss-notification');
    emitter.off('accept-notification');
    emitter.off('click-card-notification');
    PushNotifications.removeAllListeners();
    App.removeAllListeners();
  },
}
</script>

<style scoped>

</style>