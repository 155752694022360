export default {
  user: {
    id: 0,
    username: '',
    player: {
      id: '',
      name: '',
    },
    first_name: '',
    last_name: '',
    email: '',
    avatar: '/assets/avatar.svgg',
    refresh: '',
    access: '',
    verify: false,
  },
  users: {
    results: [],
    count: 0,
    next: null,
    previous: null,
  },
  userMe: {},
  playerId: null,
};
