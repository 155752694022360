import UserTypes from '../../enums/UserTypes';

export default [
  {
    path: '/tournaments',
    name: 'tournaments',
    component: () => import('../../pages/tournaments/index.vue'),
    meta: { userType: UserTypes.CLIENT },
  },
  {
    path: '/tournaments/add',
    name: 'tournament-add',
    component: () => import('../../pages/tournaments/add.vue'),
    meta: { userType: UserTypes.CLIENT },
  },
  {
    path: '/tournaments/:id',
    name: 'tournament',
    component: () => import('../../pages/tournaments/_tournament/view.vue'),
    meta: { userType: UserTypes.CLIENT },
  },
  {
    path: '/location',
    name: 'location',
    component: () => import('../../pages/location/index.vue'),
    meta: { userType: UserTypes.CLIENT },
  },
  {
    path: '/tournaments/:id/exit',
    name: 'tournament-exit',
    component: () => import('../../pages/tournaments/_tournament/exit.vue'),
    meta: { userType: UserTypes.CLIENT },
  },
  {
    path: '/teams/add',
    name: 'teams-add',
    component: () => import('../../pages/teams/add.vue'),
    meta: { userType: UserTypes.CLIENT },
  },
  {
    path: '/teams/invite',
    name: 'teams-invite',
    component: () => import('../../pages/teams/invite.vue'),
    meta: { userType: UserTypes.CLIENT },
  },
  {
    path: '/team/:id',
    name: 'team',
    component: () => import('../../pages/teams/_id.vue'),
    meta: { userType: UserTypes.CLIENT },
  },
];