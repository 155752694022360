export default {
  list: [],
  isFetching: false,
  /*list: [
    {
      id: 1,
      avatar: { type: 'color', value: '#FFD60A' },
      text: '@temasarkisov принял ваш запрос на участие в турнире «Я русский»',
      read: false,
    },
    {
      id: 2,
      avatar: { type: 'image', value: '/assets/avatar_example.jpg' },
      text: '@ptrk приглашает вас принять участие в турнире',
      tournament: {
        name: 'NFL | Superbowl',
        sport: 'Football',
        created_at: '08/09/2023',
        location: 'Dallas, USA'
      },
      read: false,
    },
    {
      id: 3,
      avatar: { type: 'color', value: '#FC6B34' },
      text: '@dimkov27 принял ваш запрос на участие в турнире «Поздняков 3.0»',
      read: false,
    },
  ]*/
};