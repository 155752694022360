import moment from 'moment';
import 'moment/locale/ru';

export default (date, type) => {
  const locale = window && typeof window.userLang === 'string' ? String(window.userLang) : null;
  if (type !== 'time') {
    moment.locale(locale || 'en');
  } else {
    moment.locale(locale);
  }

  switch (type) {
    case 'input': {
      date = moment(new Date(date)).utc();
      if (locale === 'ru') {
        return date.calendar({
          sameDay: 'Сегодня в HH:mm',
          nextDay: 'Завтра в HH:mm',
          nextWeek: 'D MMMM YYYY в HH:mm',
          lastDay: 'Вчера в HH:mm',
          lastWeek: 'D MMMM YYYY в HH:mm',
          sameElse: 'D MMMM YYYY в HH:mm'
        }); 
      } else {
        return date.calendar({
          sameDay: '[Today at] HH:mm',
          nextDay: '[Tomorrow at] HH:mm',
          nextWeek: 'dddd [at] HH:mm',
          lastDay: '[Yesterday at] HH:mm',
          lastWeek: '[Last] dddd [at] HH:mm',
          sameElse: 'D MMMM YYYY [at] HH:mm'
        });
      }
    }
    case 'match': {
      if (date === null) return '___';
      return moment(new Date(date)).utc().format('HH:mm DD/MM/YY');
    }
    case 'date-time': {
      return moment(new Date(date)).utc().format('DD/MM/YY HH:mm');
    }
    case 'date': {
      return moment(new Date(date)).utc().format('DD/MM/YY');
    }
    case 'time': {
      return moment(new Date(date)).utc().format('LT');
    }
    default: return date;
  }
};