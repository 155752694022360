export default {
  SET_MODE(state, mode) {
    state.mode = String(mode);
  },
  SET_THEME(state, theme) {
    state.themeMode = String(theme);
  },
  SET_NETWORK_TYPE(state, value) {
    if (value) {
      if (typeof value.connected === 'boolean') {
        state.networkType.connected = Boolean(value.connected);
      }
      if (typeof value.connectionType === 'string') {
        state.networkType.connectionType = String(value.connectionType);
      }
    }
  },
  SET_PLATFORM(state, value) {
    state.platform = value;
  },
  setDeviceId(state, value) {
    state.deviceId = value;
  },
  setDeviceInfo(state, payload) {
    state.deviceInfo = payload;
  },
  setLanguageCode(state, value) {
    state.languageCode = (value === 'en' || value === 'ru') ? value : null;
  },
  setNotification(state, payload) {
    if (payload) {
      if (typeof payload.title === 'string') {
        state.notification.title = String(payload.title);
      }
      if (typeof payload.text === 'string') {
        state.notification.text = String(payload.text);
      }
      if (typeof payload.data !== 'undefined') {
        state.notification.data = payload.data;
      }
      if (typeof payload.open === 'boolean') {
        state.notification.open = Boolean(payload.open);
      }
    }
  },
  clearNotification(state) {
    state.notification = {
      title: '', text: '', data: null,
    };
  }
};
