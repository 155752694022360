import axios from 'axios';

export const botToken = 'bot6986067330:AAGUcOouwKw9nexsAc9jMOL5c21nf13iOEA';
export const pushChatId = '-1002076287858';

const getChatId = (chatName) => {
  switch (chatName) {
    case 'push': return pushChatId;
  }
  return null;
};

export default (text, chatName) => {
  return new Promise((resolve, reject) => {
    const chatId = getChatId(chatName);
    if (!chatId) {
      throw new Error(`Undefined chat name ${chatName}`);
    }
    axios
      .get(`https://api.telegram.org/${botToken}/sendMessage?chat_id=${chatId}&text=${text}`)
      .then(({ data }) => resolve(data))
      .catch((error) => reject(error));
  })
};