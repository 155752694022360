import { createStore } from 'vuex';

import general from './modules/general/index';
import login from './modules/login/index';
import files from './modules/files/index';
import user from './modules/user/index';
import tournament from './modules/tournament/index';
import notifications from './modules/notifications/index';
import menu from './modules/menu/index';
import progress from '../features/ProgressView/store';

const store = createStore({
  modules: {
    general,
    login,
    files,
    user,
    menu,
    tournament,
    notifications,
    progress,
  },
});

export default store;
