<script>
import { mapGetters } from 'vuex';
import { Preferences } from '@capacitor/preferences';

export default {
  computed: {
    ...mapGetters({
      languageCode: 'general/getLanguageCode',
    }),
    availableLocales() {
      return this.$i18n && Array.isArray(this.$i18n.availableLocales) && this.$i18n.availableLocales.length > 0 ? this.$i18n.availableLocales : null;
    },
  },
  methods: {
    async setupUserLocale() {
      if (!(this.$i18n && typeof this.$i18n.locale === 'string')) {
        console.warn('Please provide $i18n.locale');
        return;
      }
      try {
        const userLang = await Preferences.get({ key: 'userLang' });
        if (userLang && userLang.value) {
          this.$i18n.locale = String(userLang.value);
        } else if (this.languageCode) {
          this.$i18n.locale = String(this.languageCode);
        }
        if (window && (typeof window.userLang === 'string' || typeof window.userLang === 'undefined')) {
          window.userLang = String(this.$i18n.locale);
        }
      } catch (error) {
        console.log(error);
      }
    },
    async clearUserLocale() {
      try {
        await Preferences.remove({ key: 'userLang' });
        this.$i18n.locale = String(this.languageCode);
        if (window && (typeof window.userLang === 'string' || typeof window.userLang === 'undefined')) {
          window.userLang = String(this.$i18n.locale);
        }
      } catch (error) {
        console.error(error);
      }
    },
    async updateUserLocale(locale = 'en') {
      if (!(this.$i18n && typeof this.$i18n.locale === 'string')) {
        console.warn('Please provide $i18n.locale');
        return;
      }
      try {
        await Preferences.set({ key: 'userLang', value: String(locale) });
        this.$i18n.locale = String(locale);
        if (window && (typeof window.userLang === 'string' || typeof window.userLang === 'undefined')) {
          window.userLang = String(this.$i18n.locale);
        }
      } catch (error) {
        console.log(error);
      }
    }
  },
}
</script>