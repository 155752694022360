import { createApp } from 'vue';
import { createI18n } from 'vue-i18n';
import { IonicVue } from '@ionic/vue';
import userToken from './api/token';
import mitt from 'mitt';
import { defineCustomElements } from '@ionic/pwa-elements/loader';
import { DarkMode } from '@aparajita/capacitor-dark-mode';

import App from './App.vue';
import router from './router';
import store from './store';
import Utils from './utils/index';

/* Locales */
import en from './locales/en.json';
import ru from './locales/ru.json';
// import redirectToHome from './composition/redirectToHome';

import BaseLayout from './components/base/BaseLayout.vue';
import ErrorMessage from './components/ErrorMessage.vue';
import Loading from './components/Loading.vue';

/* Core CSS required for Ionic components to work properly */
import '@ionic/vue/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/vue/css/normalize.css';
import '@ionic/vue/css/structure.css';
import '@ionic/vue/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/vue/css/padding.css';
import '@ionic/vue/css/float-elements.css';
import '@ionic/vue/css/text-alignment.css';
import '@ionic/vue/css/text-transformation.css';
import '@ionic/vue/css/flex-utils.css';
import '@ionic/vue/css/display.css';

/* Theme variables */
import './theme/index.css';

/* Bootstrap utilities */
import './assets/css/bootstrap-grid.min.css';

/* Custom styles */
import './assets/ion.styles.scss';
import './assets/styles/styles.scss';
import UserTypes from './enums/UserTypes';

const i18n = createI18n({
  fallbackLocale: 'en',
  locale: 'en',
  messages: {
    en, ru,
  }
  // something vue-i18n options here ...
});

router.beforeEach(async (to, from, next) => {
  const UToken = new userToken();
  const { access, refresh } = await UToken.storageToken();
  const user = () => (store.getters['user/getUser']);
  if (access && user().verify === false) {
    try {
      await store.dispatch('user/verifyToken', { access, refresh });
    } catch (error) {
      await UToken.clearToken();
      console.log(error);
    }
  }
  if (to.meta) {
    if (typeof to.meta.userType === 'number') {
      switch (to.meta.userType) {
        case UserTypes.GUEST: {
          if (user().verify === true) {
            next({ name: 'tournaments' });
          }
          break;
        }
        case UserTypes.CLIENT: {
          if (user().verify === false) {
            next({ name: 'welcome' });
          }
          break;
        }
        default: break;
      }
    }
  }

  to.matched.some(() => {
    next();
  });
});

const app = createApp(App)
  .use(IonicVue)
  .use(router)
  .use(store)
  .use(i18n);

app.config.globalProperties.emitter = mitt();
app.config.globalProperties.$validate = Utils.validations;

app.component('base-layout', BaseLayout);
app.component('error-message', ErrorMessage);
app.component('loading', Loading);


const appInit = async () => {
  try {
    await router.isReady();
    await store.dispatch('general/CHECK_NETWORK_STATUS');
    await DarkMode.init();

    app.mount('#app');
    await defineCustomElements(window);
  } catch (error) {
    console.log(error);
  }
};

appInit();
