import { Network } from '@capacitor/network';
import { Device } from '@capacitor/device';


export default {
  async CHECK_NETWORK_STATUS({ commit }) {
    try {
      const networkStatus = await Network.getStatus();
      commit('SET_NETWORK_TYPE', networkStatus);
    } catch (error) {
      console.log(error);
    }
  },
  async getDeviceID({ commit }) {
    try {
      const { identifier } = await Device.getId();
      commit('setDeviceId', identifier);
    } catch (error) {
      console.log(error);
    }
  },
  async getDeviceInfo({ commit }) {
    try {
      const deviceInfo = await Device.getInfo();
      commit('setDeviceInfo', deviceInfo);
    } catch (error) {
      console.log(error);
    }
  },
  async getLanguageCode({ commit }) {
    try {
      const { value } = await Device.getLanguageCode();
      commit('setLanguageCode', value);
    } catch (error) {
      console.log(error);
    }
  }
};
