export default {
  list: [],
  stats: [],
  users: [],
  builder: {
    players: [],
    team: {
      name: '',
      description: '',
      players: [],
    },
  },
  bundle: {
    teams: [],
    tournament_id: null,
    season_id: null,
    stage_id: null,
    team_ids: [],
  },
  tournament: {
    id: null,
    seasons: [],
    created_at: null,
    updated_at: null,
    status: null,
    name: null,
    description: null,
    color: null,
    sport: null,
    icon: null,
    participant_type: null,
    team_limit: 0,
    owner: null,
  },
  team: {
    id: '',
    name: '',
    type: '',
    players: [],
    created_at: null,
    updated_at: null,
  },
  geoCode: {
    meta: {
      request: '',
      found: 0, 
      results: 0, 
    },
    results: [],
  },
  chosenLocation: {
    coordinates: {
      latitude:  0.0,
      longitude: 0.0,
    },
    name: '',
  },
  freezeLocation: false,
};