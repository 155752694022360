export default {
  themeMode: 'light',
  deviceMode: 'md',
  networkType: {
    connected: true,
    connectionType: 'none',
  },
  platform: 'none',
  deviceId: null,
  deviceInfo: null,
  languageCode: null,
  notification: {
    title: '',
    text: '',
    data: null,
    open: false,
  },
};
