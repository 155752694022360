export default {
  setUser(state, { id, email, first_name, last_name, username, avatar }) {
    state.user = { 
      ...state.user, 
      id, email, first_name, last_name, username, 
      avatar: avatar || '/assets/avatar.svg' 
    };
  },
  setUserToken(state, { refresh, access, id }) {
    state.user = { ...state.user, refresh, access, id, verify: true };
  },
  setUserAvatar(state, url) {
    state.user.avatar = url;
  },
  clearUserInfo(state) {
    state.user = {
      ...state.user, first_name: '', last_name: '', email: '', avatar: '',
    };
  },
  clearUser(state) {
    state.user = { 
      ...state.user,
      id: 0,
      username: '',
      first_name: '',
      last_name: '',
      email: '',
      refresh: '',
      access: '',
      verify: false,
    };
  },
  setUsers(state, { results, count, next, previous }) {
    results
      .filter(user => (state.users.results.findIndex(({ id }) => (user.id === id)) === -1))
      .forEach(user => state.users.results.push(user));
    state.users = {
      ...state.users,
      count, 
      next: next ? next.replace('http', 'https') : next,
      previous: previous ? previous.replace('http', 'https') : previous,
    };
  },
  clearUsers(state) {
    state.users = {
      count: 0,
      next: null,
      previous: null,
      results: [],
    };
  },
  clearUserMe(state) {
    state.userMe = {};
  },
  setUserMe(state, payload) {
    state.userMe = payload;
  },
  setPlayerId(state, value) {
    state.playerId = value;
  }
};
