export default {
  list(state) {
    return state.list;
  },
  bundle(state) {
    return state.bundle;
  },
  builderPlayers(state) {
    return state.builder.players;
  },
  builderTeam(state) {
    return state.builder.team;
  },
  getTournament(state) {
    return state.tournament;
  },
  getTeam(state) {
    return state.team;
  },
  getStats(state) {
    return state.stats;
  },
  getGeoCode(state) {
    return state.geoCode;
  },
  getChosenLocation(state) {
    return state.chosenLocation;
  },
  freezeLocation(state) {
    return state.freezeLocation;
  }
};