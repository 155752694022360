import api from '../../../api/index';
import userToken from '../../../api/token';

export default {
  async userLogout({ commit }) {
    await new userToken().clearToken();
    commit('clearUser')
  },
  async authRegister({ commit, dispatch }, { email, username, password }) {
    return new Promise(async (resolve, reject) => {
      try {
        await api.post('/auth/register/', { email, username, password });
        await dispatch('authToken', { username, password });
        resolve();
      } catch (error) {
        reject(error);
      }
    });
  },
  updateToken({ commit }) {
    return new Promise(async (resolve, reject) => {
      try {
        const { refresh } = await new userToken().storageToken();
        const response = await api.post('/auth/token/refresh/', { refresh });
        if (response.data) {
          const { user_id, player_id } = await new userToken({ access: response.data.access, refresh: response.data.refresh }).storageToken(true);
          commit('setUserToken', { access: response.data.access, refresh: response.data.refresh, id: user_id || 0 });
          commit('setPlayerId', player_id);
          resolve({ access: response.data.access, refresh: response.data.refresh });
        } else {
          reject(new Error('Undefined response'))
        }
      } catch (error) {
        reject(error);
      }
    }); 
  },
  verifyToken({ commit, dispatch }, { access, refresh }) {
    return new Promise(async (resolve, reject) => {
      try {
        await api.post('/auth/token/verify/', { token: access });
        const { user_id, player_id } = await new userToken({ access, refresh }).storageToken();
        commit('setUserToken', { access, refresh, id: user_id || 0 });
        commit('setPlayerId', player_id);
        await dispatch('getUser', { user_id });
        resolve({ access, refresh });
      } catch (e) {
        try {
          const update = await dispatch('updateToken');
          resolve({ access: update.access, refresh: update.refresh });
        } catch (error) {
          reject(error); 
        }
      }
    });
  },
  async getUser({ commit, dispatch }) {
    commit('clearUserInfo');
    
    try {
      const { user_id, player_id } = await new userToken().storageToken();
      if (user_id) {
        const response = await api.get(`/user/${user_id}/`);
        if (response.data) {
          const { username, email, first_name, last_name, id, player, avatar } = response.data;
          commit('setUser', { username, email, first_name, last_name, id, player, avatar });
        } 
      }
      if (player_id) {
        commit('setPlayerId', player_id);
      }
    } catch (error) {
      console.log(error);
    }
  },
  async getUserMe({ getters, commit }) {
    commit('clearUserMe');
    
    try {
      const response = await api.get('/user/', {
        params: { username: getters.getUser.username }
      });
      if (response && response.data) {
        if (Array.isArray(response.data.results) && (typeof response.data.count === 'number' && response.data.count > 0)) {
          const userMe = response.data.results.find(({ username }) => (username === getters.getUser.username));
          if (userMe) {
            commit('setUserMe', userMe);
          }
        }
      }
    } catch (error) {
      console.log(error);
    }
  },
  async getUsers({ commit, getters }, { username } = { username: null }) {
    if (typeof username === 'string') {
      commit('clearUsers');
    }
    try {
      const response = await api.get(getters.getUsers.next || '/user/', {
        params: { username },
      });
      if (response && response.data) {
        if (Array.isArray(response.data.results) && (typeof response.data.count === 'number' && response.data.count > 0)) {
          commit('setUsers', response.data);
        } else {
          commit('clearUsers');
        }
      } else {
        commit('clearUsers');
      }
    } catch (error) {
      console.log(error);
    }
  },
  async updateUser({ commit, getters }, payload) {
    try {
      const formData = new FormData();
      formData.append('username', String(getters.getUser.username));
      formData.append('id', String(getters.getUser.id));
      Object.keys(payload).forEach((key) => {
        const value = payload[key];
        if (typeof value === 'object' && (value.blob && value.filename)) {
          const { blob, filename } = value;
          formData.append(key, blob, filename);
        } else {
          formData.append(key, value);
        }
      });
      await api.patch(`/user/${getters.getUser.id}/`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        }
      });
    } catch (error) {
      console.log(error);
    }
  },
  changePassword({ getters }, { current_password, new_password }) {
    return new Promise(async (resolve, reject) => {
      api.post(`/user/${getters.getUser.id}/change_password/`, { current_password, new_password }).then(() => resolve()).catch(error => reject(error));
    });  
  },
  authToken({ commit }, { username, password }) {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await api.post('/auth/token/', { username, password });
        if (response.data) {
          const { access, refresh } = response.data;
          const { user_id, player_id } = await new userToken({ access, refresh }).storageToken();
          commit('setUserToken', { access, refresh, id: user_id || 0 });
          commit('setPlayerId', player_id);
          resolve();
        } else {
          reject(new Error(response));
        }
      } catch (error) {
        reject(error);
      }
    });
  },
};
