const isString = value => Boolean(typeof value === 'string' && value.length > 0);
import parseDate from '../../../shared/helpers/parseDate.js';

export default {
  setReadNotify(state, { id, value }) {
    const index = state.list.findIndex(notify => (notify.id === id));
    if (index > -1) {
      state.list[index].read = value;
    }
  },
  clearList(state) {
    state.list = [];
  },
  notifyAvatarLoad(state, id) {
    const notify = state.list.find(notify => (notify.id === id));
    if (notify && (notify.avatar && notify.avatar.type === 'image' && typeof notify.avatar.loaded === 'boolean')) {
      notify.avatar.loaded = true;
    }
  },
  setList(state, payload) {
    payload.forEach((item) => {
      const notify = {
        id: Number(item.id),
        read: Boolean(item.read),
      };
      // Fill user from
      if (item.user_from) {
        const user_from = item.user_from;
        
        if (isString(user_from.avatar)) {
          notify.avatar = { type: 'image', value: user_from.avatar, loaded: false };
        } else {
          notify.avatar = { type: 'image', value: '/assets/avatar.svg', loaded: false };
        }
        
        notify.text = {};
        if (isString(user_from.first_name)) {
          notify.text.first_name = String(user_from.first_name);
        }
        if (isString(user_from.last_name)) {
          notify.text.last_name = String(user_from.last_name);
        }
        if (isString(user_from.username)) {
          notify.text.username = String(user_from.username);
        }
      }
      // Fill tournament
      if (item.tournament) {
        notify.tournament = {};
        
        if (typeof item.tournament.id === 'number') {
          notify.tournament.id = Number(item.tournament.id);
        }
        if (isString(item.tournament.name)) {
          notify.tournament.name = String(item.tournament.name);
        }
        if (isString(item.tournament.description)) {
          notify.tournament.description = String(item.tournament.description);
        }
        if (item.tournament.created_at) {
          notify.tournament.created_at = parseDate(item.tournament.created_at, 'input');
        }
      }
      // Fill team
      if (item.team) {
        notify.team = {};
        
        if (typeof item.team.id === 'string') {
          notify.team.id = String(item.team.id);
        }
        if (isString(item.team.name)) {
          notify.team.name = String(item.team.name);
        }
        if (isString(item.team.description)) {
          notify.team.description = String(item.team.description);
        }
      }
      notify.show = true;
      state.list.push(notify);
    });
  },
  setFetching(state, value) {
    state.fetching = Boolean(value);
  },
};
