export default {
  getImagesAddress() {
    return process.env.VUE_APP_IMAGES_ADDRESS;
  },
  getApiUrl() {
    return process.env.VUE_APP_API_URL;
  },
  getWhatsappTelephone() {
    return process.env.VUE_APP_WHATS_TELEPHONE;
  },
  getContactEmail() {
    return process.env.VUE_APP_CONTACT_EMAIL;
  },
  getThemeMode(state) {
    return state.themeMode;
  },
  getDeviceMode(state) {
    return state.deviceMode;
  },
  getNetworkType(state) {
    return state.networkType;
  },
  getNetworkStatus(state) {
    return Boolean(state.networkType.connected === true) ? 'online' : 'offline';
  },
  clientOnline(state) {
    return Boolean(state.networkType.connected === true);
  },
  clientOffline(state) {
    return Boolean(state.networkType.connected === false);
  },
  getPlatform(state) {
    return String(state.platform);
  },
  getDeviceID(state) {
    return state.deviceId;
  },
  getDeviceInfo(state) {
    return String(state.deviceInfo);
  },
  getLanguageCode(state) {
    return state.languageCode;
  },
  getNotification(state) {
    return state.notification;
  },
};
