import api from "Api";

export default {
  async fetchProgress({ rootGetters, getters, commit }) {
    const currentTournament = rootGetters['tournament/getTournament'];
    if ((currentTournament && currentTournament.seasons) && Array.isArray(currentTournament.seasons) && currentTournament.seasons.length > 0) {
      commit('setSeasons', currentTournament.seasons);
      
      const stableSeason = getters.seasons[0];
      if (stableSeason.stages && (Array.isArray(stableSeason.stages) && stableSeason.stages.length > 0)) {
        commit('setStages', stableSeason.stages);
        
        const stableStage = getters.stages[0];
        if (stableStage.teams && (Array.isArray(stableStage.teams) && stableStage.teams.length > 0)) {
          commit('setTeams', stableStage.teams);
        }
        if (stableStage.series && (Array.isArray(stableStage.series) && stableStage.series.length > 0)) {
          commit('setSeries', stableStage.series);
        }
      }
    }
  },
  async updateMatch({ commit }, { match, score1, score2, start, tournament_id }) {
    try {
      let response = await api.patch(`/match/${match.id}/`, {
        score1, score2, start,
      });
      commit('updateMatch', { ...match, ...response.data });
      
      response = await api.get(`/tournament/${tournament_id}/`);
      const { seasons } = response.data;
      if (Array.isArray(seasons) && seasons.length > 0) {
        const stableSeason = seasons[0];
        if (stableSeason.stages && (Array.isArray(stableSeason.stages) && stableSeason.stages.length > 0)) {
          const stableStage = stableSeason.stages[0];
          if (stableStage.series && (Array.isArray(stableStage.series) && stableStage.series.length > 0)) {
            commit('setSeries', stableStage.series);
          }
        }
      }
    } catch (error) {
      console.log(error);
    }
    // return new Promise((resolve, reject) => {
      // api.patch(`/match/${match.id}/`, {
      //   score1, score2, start,
      // }).then(({ data }) => {
      
      //  
      //   console.log({
      //     ...match, ...data,
      //   });
      //   resolve(data);
      // }).catch(error => reject(error));
   // });
  },
}