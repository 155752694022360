export default {
  list(state) {
    return state.list;
  },
  activeList(state) {
    return state.list.filter(({ show, read }) => (show === true && read === false)).sort((a, b) => (b.id - a.id));
  },
  unreadCount(state) {
    return Number(state.list.filter(({ read }) => (read === false)).length);
  },
  getFetching(state) {
    return state.fetching;
  }
};