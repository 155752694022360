import { createRouter, createWebHistory } from '@ionic/vue-router';

import client from './authenticated/client';
import admin from './authenticated/admin';
import tournaments from './authenticated/tournaments';
import notifications from './authenticated/notifications';
import invite from './authenticated/invite';
import UserTypes from '../enums/UserTypes';


const routes = [
  {
    path: '/', redirect: '/welcome',
    meta: { userType: UserTypes.GUEST },
  },
  {
    path: '/welcome', name: 'welcome', component: () => import('../pages/Welcome.vue'),
    meta: { userType: UserTypes.GUEST },
  },
  {
    path: '/login', name: 'login', component: () => import('../pages/Login.vue'),
    meta: { userType: UserTypes.GUEST },
  },
  {
    path: '/register', name: 'register', component: () => import('../pages/Register.vue'),
    meta: { userType: UserTypes.GUEST },
  },
  ...client,
  ...admin,
  ...tournaments,
  ...notifications,
  ...invite,
  {
    path: '/:catchAll(.*)',
    name: 'not-found',
    redirect: '/welcome',
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
