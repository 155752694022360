import axios from 'axios';
import userToken from './token';
// import { Storage } from '@capacitor/storage';

const baseURL = process.env.VUE_APP_API_URL;

const api = axios.create({
  baseURL,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
});

api.interceptors.request.use(async (config) => {
  const { access } = await new userToken().storageToken();
  if (access) {
    config.headers.Authorization = `Bearer ${access}`;
  }
  return config;
});

api.interceptors.response.use((response) => response,
  (error) => {
    // if (error.response.status === 401) {
    //   window.location = '/logout';
    // }

    return Promise.reject(error);
  });

export default api;
