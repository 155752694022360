<template>
  <ion-app>
    <ProfileLayout v-if="isClientMode" />
    <ion-router-outlet v-else id="main-content" />
  </ion-app>
</template>

<script>
import {
  IonApp,
  IonRouterOutlet,
} from '@ionic/vue';
import { Capacitor } from '@capacitor/core';
import { Network } from '@capacitor/network';
import { defineComponent } from 'vue';
import { mapGetters, mapMutations, mapActions, useStore } from 'vuex';
import { useRoute, useRouter } from 'vue-router';
import ProfileLayout from './components/ProfileLayout.vue';
import AppNotifications from './mixins/AppNotifications.vue';
import userTypes from './enums/UserTypes';
import { DarkMode } from '@aparajita/capacitor-dark-mode';

export default defineComponent({
  name: 'App',
  components: {
    IonApp,
    IonRouterOutlet,
    ProfileLayout,
  },
  mixins: [AppNotifications],
  data: () => ({
    userTypes,
    appearanceListenerHandle: null,
    networkListenerHandle: null,
  }),
  computed: {
    ...mapGetters({
      themeMode: 'general/getThemeMode',
      platform: 'general/getPlatform',
    }),
    isClientMode() {
      return Boolean(this.route && this.route.meta && typeof this.route.meta.userType === 'number' && this.route.meta.userType === this.userTypes.CLIENT); 
    },
  },
  setup() {
    const { commit } = useStore();
    commit('general/SET_PLATFORM', Capacitor.getPlatform());
    const route = useRoute();
    const router = useRouter();
    return { route, router };
  },
  mounted() {
    this.setupUserLocale().then(() => {
      this.getNotificationsList().then(() => {});
    });
    this.addAppearanceListenerHandle();
    this.addNetworkListenerHandle();
  },
  destroyed() {
    if (this.appearanceListenerHandle) {
      this.appearanceListenerHandle.remove();
    }
    if (this.networkListenerHandle) {
      this.networkListenerHandle.remove();
    }
  },
  methods: {
    ...mapActions({
      checkNetworkStatus: 'general/CHECK_NETWORK_STATUS',
      getNotificationsList: 'notifications/getList',
    }),
    ...mapMutations({
      setPlatform: 'general/SET_PLATFORM',
      setTheme: 'general/SET_THEME',
      setNetworkType: 'general/SET_NETWORK_TYPE',
    }),
    async addNetworkListenerHandle() {
      this.networkListenerHandle = await Network.addListener('networkStatusChange', (status) => {
        console.warn('Network status changed', status);
        this.setNetworkType(status);
      });
      await this.checkNetworkStatus();
    },
    async addAppearanceListenerHandle() {
      this.appearanceListenerHandle = await DarkMode.addAppearanceListener(
        ({ dark }) => {
          this.setTheme(dark ? 'dark' : 'light');
          console.log(`System dark mode is ${dark ? 'on' : 'off'}.`);
        },
      );
      await DarkMode.update();
      
      const { dark } = await DarkMode.isDarkMode();
      this.setTheme(dark ? 'dark' : 'light');
    },
  },
});
</script>
