<template>
  <ion-card class="dialog__card" @click="clickCard">
    <ion-card-header v-if="validTitle" class="dialog__card__header">
      <ion-card-title class="dialog__card__title" v-html="title" />
    </ion-card-header>
    <ion-card-content v-if="validText" class="dialog__card__content" v-html="text" />
    <div class="dialog__card__actions">
      <ion-button v-if="validAccept" class="dialog__card__button dialog__card__button_accept" fill="clear" color="primary" v-html="acceptText" @click="onAccept" />
      <ion-button v-if="validDismiss" class="dialog__card__button dialog__card__button_dismiss" :color="dismissColor" fill="clear" v-html="dismissText" @click="onDismiss" />
    </div>
  </ion-card>
</template>

<script>
import { IonCard, IonCardHeader, IonCardTitle, IonCardContent, IonButton } from '@ionic/vue';
import { emitter } from '../../../composition/useEmitter';

export default {
  name: 'CardNotification',
  props: {
    title: {
      type: String, required: false, default: 'Уведомление',
    },
    text: {
      type: String, required: false, default: 'Текст уведомления',
    },
    acceptText: {
      type: String, required: false, default: '',
    },
    dismissText: {
      type: String, required: false, default: '',
    },
  },
  components: {
    IonCard, IonCardHeader, IonCardTitle, IonCardContent, IonButton,
  },
  computed: {
    validTitle() {
      return Boolean(typeof this.title === 'string' && this.title.length > 0);
    },
    validText() {
      return Boolean(typeof this.text === 'string' && this.text.length > 0);
    },
    validAccept() {
      return Boolean(typeof this.acceptText === 'string' && this.acceptText.length > 0);
    },
    validDismiss() {
      return Boolean(typeof this.dismissText === 'string' && this.dismissText.length > 0);
    },
    dismissColor() {
      return !this.validAccept ? 'primary' : 'medium';
    },
  },
  methods: {
    onDismiss() {
      emitter.emit('dismiss-notification');
    },
    onAccept() {
      emitter.emit('accept-notification');
    },
    clickCard() {
      emitter.emit('click-card-notification');
    },
  },
}
</script>

<style scoped>

</style>