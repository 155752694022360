import UserTypes from '../../enums/UserTypes';

export default [
  {
    path: '/profile',
    name: 'profile',
    component: () => import('../../pages/profile/index.vue'),
    meta: { userType: UserTypes.CLIENT },
  },
  {
    path: '/profile/edit',
    name: 'profile-edit',
    component: () => import('../../pages/profile/edit.vue'),
    meta: { userType: UserTypes.CLIENT },
  },
  {
    path: '/profile/password',
    name: 'profile-password',
    component: () => import('../../pages/profile/password.vue'),
    meta: { userType: UserTypes.CLIENT },
  },
  {
    path: '/profile/lang',
    name: 'profile-lang',
    component: () => import('../../pages/profile/lang.vue'),
    meta: { userType: UserTypes.CLIENT },
  }
];
